<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <b-container fluid style="padding-right: 12.5px; padding-left: 12.5px">
        <b-row class="my-0">
          <b-col sm="3">
            <b-row class="my-0">
              <label>日期查询:</label>
            </b-row>
            <b-row class="my-0">
              <v-app><v-dialog
                ref="dialog"
                v-model="datePickerModal.modal"
                :return-value.sync="dates"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  scrollable
                  full-width
                  range
                  :first-day-of-week="1"
                  locale="zh-cn"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="datePickerModalCancel()"
                  >
                    取消
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="datePickerModalOK()"
                  >
                    确认
                  </v-btn>
                </v-date-picker>
              </v-dialog></v-app>
            </b-row>
          </b-col>

          <b-col sm="1">
            <b-row class="my-0">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-0">
              <b-button
                variant="success"
                @click="dayDataList()"
                style="margin-right: 10px"
                >当 日</b-button
              >
            </b-row>
          </b-col>

          <b-col sm="1">
            <b-row class="my-0">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-0">
              <b-button
                variant="success"
                @click="weekDataList()"
                style="margin-right: 10px"
                >当 周</b-button
              >
            </b-row>
          </b-col>

          <b-col sm="1">
            <b-row class="my-0">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-0">
              <b-button
                variant="success"
                @click="monthDataList()"
                style="margin-right: 10px"
                >当 月</b-button
              >
            </b-row>
          </b-col>

        </b-row>
        <b-row class="my-0 mtop-50"
          style="margin-bottom: -20px !important;margin-top: -30px !important;">
          <b-col sm="3">
            <b-row class="my-0">
              <label>商品名称查询:</label>
            </b-row>
            <b-row class="my-0">
              <LcxSearchSelect 
                ref="searchVegetableNameRef" 
                @changeItemId="changeVegetableId"
                myplaceholder="请输入商品名称"
              ></LcxSearchSelect>
            </b-row>
          </b-col>
          
          <b-col sm="3">
            <b-row class="my-0">
              <label>供应商名称查询:</label>
            </b-row>
            <b-row class="my-0">
              <LcxSearchSelect 
                ref="searchSupplierNameRef" 
                @changeItemId="changeSupplierId"
                myplaceholder="请输入供应商名称"
              ></LcxSearchSelect>
            </b-row>
          </b-col>

          <b-col sm="3">
            <b-row class="my-0">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-0">
              <b-button
                variant="primary"
                @click="searchDataList()"
                style="margin-right: 10px"
                >搜 索</b-button
              >
              <b-button
                variant="info"
                @click="exportExcel()"
                style="margin-right: 10px"
                >导出Excel</b-button
              >
            </b-row>
          </b-col>

          

          <b-col sm="3">
            <b-row class="my-0">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-0" style="float: right;">
              <span class="btn btn-success" style="
                  cursor: default;
                  background-color: #38485e;
                  border-color: #38485e;
                  font-weight: bold;
              "> 合计金额：{{ Math.round(totalAllPrice * 100) / 100 }} </span>
            </b-row>
          </b-col>

        </b-row>
      </b-container>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-vertical-center">
          <thead>
            <tr>
              <th class="pl-0 text-center" style="width: 50px">#</th>
              <th class="pl-0" style="min-width: 100px">供应商</th>
              <th class="pl-0" style="min-width: 150px">商品名称</th>
              <th class="pl-0" style="min-width: 50px">数量</th>
              <th class="pl-0" style="min-width: 50px">单位</th>
              <th class="pl-0" style="min-width: 50px">单价</th>
              <th class="pl-0" style="min-width: 50px">总金额</th>
            </tr>
          </thead>
          <tbody v-for="(itemObj, k) in orderGroup" v-bind:key="k">
            <template v-for="(item, i) in itemObj.list">
              <tr v-bind:key="(i+1)*1000">
                <td class="pl-0 text-center">
                  {{ i + 1 }}
                </td>

                <!-- 供应商  -->
                <td class="pl-0">
                  {{ item.supplierName }}
                </td>

                <!-- 商品名称   -->
                <td class="pl-0">
                  {{ item.vegetableCode }} {{ item.vegetableName }}<br/>
                  {{ item.vegetableTypeName }}
                </td>

                <!-- 数量  -->
                <td class="pl-0">
                  {{ item.num }}
                </td>

                <!-- 单位  -->
                <td class="pl-0">
                  {{ item.unit }}
                </td>

                <!-- 单价 -->
                <td class="pl-0">
                  {{ Math.round(item.price * 100) / 100 }}
                </td>

                <!-- 总金额 -->
                <td class="pl-0">
                  {{ Math.round(item.price * item.num * 100) / 100 }}
                </td>
              </tr>
            </template>
            <tr class="tr-sum"><td colspan="7">
              <span class="btn btn-success" style="
                  cursor: default;
                  background-color: #38485e;
                  border-color: #38485e;
                  font-weight: bold;
              "> {{ itemObj.GroupName }} - 合计：{{ itemObj.totalPrice }} </span>
            </td></tr>
          </tbody>
        </table>

      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->

  </div>
</template>

<style lang="scss">
.my-0 {
  padding-right: 10px;
}
.mtop-50 {
  margin-top: -50px !important;
}
.tr-sum {
  // background-color: white;
  // color: black;
  font-weight: bold;
  // td {
  //   text-align: center;
  // }
}
</style>

<script>
let that;

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import SupplierReport from "@/assets/js/pages/vegetable/supplier-report.js";
import Order from "@/assets/js/pages/vegetable/order.js";
import LcxSearchSelect from "@/view/pages/vegetable/include/search-select.vue";

export default {
  name: "supplier-report",

  data() {
    return {
      search: {
        pageNum: 1,
        // pageSize: 5,
        startDate: "",
        endDate: "",
        vegetableName: "",
        customerName: "",
        supplierName: "",
        vegetableId: "",
        customerId: "",
        supplierId: ""
      },
      datePickerModal: {
        modal: false,
        use: false
      },
      dates: [
        '2021-08-26',
        '2021-08-26'
      ],
      rows: 100,
      orderGroup: {},
      totalAllPrice: 0
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    dateRangeText () {
      return this.dates.join(' ~ ')
      // if (this.datePickerModal.use) {
      //   return this.dates.join(' ~ ')
      // }
      // return ''
    }
  },
  components: {
    LcxSearchSelect
  },
  watch: {
  },
  mounted() {
    that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "账户管理", route: "" },
      { title: "账户列表" }
    ]);
    this.monthDataList();
    this.getSearchItemList();
  },
  methods: {
    exportExcel: function() {
      SupplierReport.exportExcel(that.search);
    },
    getSearchItemList: () => {
      // that = this;
      Order.getSelectDataList({
        vegetable: true,
        supplier: true,
        customer: false
      }).then(({ data }) => {
        that.$refs.searchVegetableNameRef.setDataList(data.vegetableList);
        // that.$refs.searchCustomerNameRef.setDataList(data.customerList);
        that.$refs.searchSupplierNameRef.setDataList(data.supplierList);
      });
    },
    changeVegetableId: (itemId) => {
      // console.log("changeVegetableId")
      that.search.vegetableId = itemId;
      that.searchDataList();
    },
    changeSupplierId: (itemId) => {
      // console.log("changeSupplierId")
      that.search.supplierId = itemId;
      that.searchDataList();
    },
    changeCustomerId: (itemId) => {
      // console.log("changeCustomerId")
      that.search.customerId = itemId;
      that.searchDataList();
    },
    datePickerModalCancel: function () {
    //  that.datePickerModal.use = false;
     that.datePickerModal.modal = false;
    //  that.searchDataList();
    },
    datePickerModalOK: function () {
     that.datePickerModal.use = true;
     that.$refs.dialog.save(that.dates);
     that.searchDataList();
    },
    groupArr(list, field) {
      var fieldList = new Set();
      var att = [];
      that.totalAllPrice = 0;

      list.map(e => {
        fieldList.add(e[field]);
        that.totalAllPrice += e.price * e.num;
      });

      for (let item of fieldList) {
        // var arr = list.filter(e => {
        //   return e[field] == item;
        // });

        var totalPrice = 0;
        var arr = [];
        list.map(e => {
          (e[field] == item) && (totalPrice += e.price * e.num, arr.push(e));
        });

        att.push({
          GroupName: item,
          totalPrice: Math.round(totalPrice * 100) / 100,
          list: arr
        });
      }
      return att;
    },
    yyyyMMdd: function(y, m, d) {
      (m < 10) && (m = "0" + m);
      (d < 10) && (d = "0" + d);
      return y + "-" + m + "-" + d;
    },
    getMonday: function(dd) {
      var week = dd.getDay(); //获取时间的星期数
      var minus = week ? week - 1 : 6;
      dd.setDate(dd.getDate() - minus); //获取minus天前的日期
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取月份
      var d = dd.getDate();
      return that.yyyyMMdd(y, m, d);
    },
    getMonthFirstDay: function(dd) {
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取月份
      var d = 1;
      return that.yyyyMMdd(y, m, d);
    },
    getToday: function() {
      let dd = new Date();
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取月份
      var d = dd.getDate();
      return that.yyyyMMdd(y, m, d);
    },
    dayDataList: function () {
      that.search.startDate = that.getToday();
      that.search.endDate = that.getToday();
      that.dates = [that.search.startDate, that.search.endDate];
      that.orderListMap();
    },
    weekDataList: function () {
      that.search.startDate = that.getMonday(new Date());
      that.search.endDate = that.getToday();
      that.dates = [that.search.startDate, that.search.endDate];
      that.orderListMap();
    },
    monthDataList: function () {
      that.search.startDate = that.getMonthFirstDay(new Date());
      that.search.endDate = that.getToday();
      that.dates = [that.search.startDate, that.search.endDate];
      that.orderListMap();
    },
    searchDataList: function () {
      // if (that.datePickerModal.use) {
      //   that.search.startDate = that.dates[0];
      //   that.search.endDate = that.dates[1];
      // } else {
      //   that.search.startDate = null;
      //   that.search.endDate = null;
      // }
      that.search.startDate = that.dates[0];
      that.search.endDate = that.dates[1];
      that.orderListMap();
    },
    orderListMap() {
      SupplierReport.list(that.search).then(({ data }) => {
        // that.orderList = data;
        that.orderGroup = that.groupArr(data, "supplierName");
      });
    }
  }
};
</script>
